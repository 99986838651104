import * as ActionTypes from './actions';
import axios from 'axios';


export const getFirebase = (path, variableName, type = 'element') => {
  return (dispatch, getState) => {
    //var token = getState().token;
    var URL = "https://animbase-87e0f.firebaseio.com/"+path+".json";//?auth=" + token;
    fetch(URL).catch(err =>
      console.log(err)
    ).then(
      res => res.json(),
    ).then(
      parsedRes => {
        let data = parsedRes;
        if(type === 'array'){
          data = [];
          for (var key in parsedRes) {
            data.push({
              ...parsedRes[key],
              key: key,
            })
          }
        }
        //console.log(variableName)
        dispatch(setFirebase(data, variableName))
      }
    )
  }
}

export const addFirebase = (data, path, method='POST') => {
  /*return (dispatch, getState) => {
    var URL = "https://animbase-87e0f.firebaseio.com/" + path + ".json";
    fetch(URL, {
        method: method,
        body: JSON.stringify({
          ...data,
          key: null,
        })
    }).catch(err =>
      console.log(err)
      ).then(
        dispatch(getFirebase(path))
      )
  }*/
}

export const authentication = () => {
  return(dispatch) =>{
    /*axios.get('https://ak.itk.ppke.hu/whoami/index.php')
      .then(res => {
        console.log(res.data)
        dispatch(getFirebase('users/' + res.data.uid, 'userData'))
      }
    )*/
  }
}

export const delFirebase = (path, key, variableName, type='element') => {
  /*return (dispatch) => {
    var URL = "https://animbase-87e0f.firebaseio.com/"+path+'/'+key +".json";
    console.log(URL);
    fetch(URL, {
      method: 'DELETE',
    }).catch(err=>
        console.log(err)
      ).then(
        dispatch(getFirebase(path, variableName, type))
      )
  }*/
}

export const setFirebase = (data, variableName) => {
  return {type: ActionTypes.SET_FIREBASE, data: data, variableName:variableName}
}

export const pressStorageButton = (id) => {
  return {type: ActionTypes.PRESS_STORAGE_BUTTON, id: id}
}