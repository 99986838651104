import React from 'react';
import { connect } from 'react-redux';
import {
  getFirebase,
  addFirebase,
  setFirebase,
} from '../Store/functions.js';
import { withStyles } from '@material-ui/core/styles';

import AddDialog from './AddDialog.js';
import DeleteDialog from './DeleteDialog.js';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import PieChart from './PieChart/PieChart.js';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = theme => ({
  /*root: {
    flexGrow: 1,
    padding: 50,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },*/
  numberTextfield:{
    width: '50px'
  },
  mainSorterButton:{

  },
  simpleSorterButton:{
    color: '#ccc',
  },
  rotten:{
    backgroundColor: 'rgba(230, 46, 0, 0.5)',
  },
  almostRotten:{
    backgroundColor: 'rgba(255, 255, 0, 0.5)',
  },
  fresh:{
    backgroundColor: 'rgba(124, 252, 0, 0.5)',
  },
  colors:{
    width: 20,
    height: 20,
    border: '1px solid grey',
    borderRadius: '100%',
  },
});


const titles = [{
    name:'Műveletek',
    key: null,
  }, {
    name:'Név',
    key: 'name',
  },{
    name: 'Mennyiség',
    key: 'quantity',
  },{
    name: 'Hely',
    key: 'room',//place???
  },{
    name: 'Tulaj',
    key: 'owner',
  },{
    name: 'Lejárati dátum',
    key: 'expiration_date',
  },{
    name: 'Szín',
    key:'colors',
  },{
    name: 'Brigád',
    key: 'brigades',
  },{
    name: 'Kategória',
    key: 'category',
  }];

const units = ['db', 'l', 'kg', 'doboz'];


class StorageTable extends React.Component{
  state={
    editableElement: null,
    deletableElementKey: null,
    deleteDialogOpen: false,
    addDialogOpen: false,
    actualDate: new Date(),
    sortKey: 'name',
    sortType: 'asc',
    search: '',
    defaultData:{
      name: '',
      quantity: 1,
      unit: 'db',
      expiration_date: null,
      room: '-',
      place: '-',
      category: '-',
      owner: '-',
      colors: [],
      brigades: '-',
    },
    datePickerOpen: false,
  }

  componentDidMount = () => {
    this.props.getStorage();
    this.setState({ ...this.state,
      actualDate: new Date(),
    })
  }

  handleEdit = (newEditable) => {
    this.setState({...this.state,
      editableElement: newEditable
    })
  }

  handleDelete = (key) => {
    this.setState({...this.state,
      deleteDialogOpen: true,
      deletableElementKey: key,
    })
  }

  convertExpirationDate = (date) => {
    return date.substring(0,4) + '.' + date.substring(5,7) + '.' + date.substring(8,10) + '.';
  }

  filteringTable = () => {
    let placeFilteredArray =
      this.props.buttonArray.length === 0 ?
      this.props.storage :
      this.props.storage.filter(element => this.props.buttonArray.indexOf(element.place) > -1);

    if(this.state.search == null || this.state.search === '')
      return placeFilteredArray;

    return placeFilteredArray.filter(element =>
      element.name.toLowerCase().search(this.state.search.toLowerCase()) !== -1);
  }

  changeSort = (newKey) => {
    let type = 'asc';
    if(newKey === this.state.sortKey && this.state.sortType === 'asc'){
      type = 'desc';
    };
    this.setState({
      ...this.state,
      sortKey: newKey,
      sortType: type,
    })
  }

  sortTable = (data, key, order = 'asc') => {
    data.sort((a,b) => {
      if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)){
        if(!b.hasOwnProperty(key))
          return -1;
        else
          return 0;
      }
      let A = a[key];
      let B = b[key];
      if(!Number.isFinite(A)) A.toLowerCase();
      if(!Number.isFinite(B)) B.toLowerCase();
      
      let result = 0;
      if(A > B)
        result = 1;
      else if(A < B)
        result = -1;

      return (order === 'desc' ? -result : result);
    })
    return data;
  }

  coloring = (row) => {
    var deadline = new Date(this.state.actualDate);
    deadline.setMonth(this.state.actualDate.getMonth()+1);
    switch(this.props.coloringType){
      case 'freshability':
        if(row.expiration_date == null)
          return null;
        if(this.state.actualDate > new Date(row.expiration_date))
          return this.props.classes.rotten;
        if(deadline > new Date(row.expiration_date))
          return this.props.classes.almostRotten;
        return this.props.classes.fresh;
      default:
        return null;
    }
  }

  setEditable = (id, data) => {
    this.setState({...this.state,
      editableElement:{...this.state.editableElement,
        [id]: data,
      }});
  }

  editOff = () => {
    this.setState({...this.state, editableElement: null})
  }

  saveEditable = (data,key) => {
    this.props.editData(data,key);
    this.editOff();
  }

  
  render(){
    const {classes} = this.props;
    return (
      <div /*className={classes.root}*/ >
        <Paper /*className={classes.paper}*/>
          <Grid container justify="flex-end" alignItems="center">
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <TextField
                  id="search"
                  label="Keresés..."
                  type='text'
                  value={this.state.search}
                  onChange={event => this.setState({...this.state, search:event.target.value})}
                />
              </Grid>
            </Grid>
            <Typography>
              Új elem:
            </Typography>
            <Tooltip title="Hozzáad">
              <IconButton onClick={() => this.setState({...this.state, addDialogOpen: true})}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {titles.map(title =>(
                  <TableCell key={title.name}>
                    {title.name}
                    {title.name === 'Műveletek' || title.name === 'Szín' ? null :
                      <IconButton
                        size='small'
                        onClick={() => this.changeSort(title.key)}
                        className={this.state.sortKey === title.key ? classes.mainSorterButton: classes.simpleSorterButton}
                      >
                        {title.key === this.state.sortKey && this.state.sortType === 'desc' ? <ArrowUpwardRoundedIcon/> : <ArrowDownwardRoundedIcon/>}
                      </IconButton>
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.sortTable(this.filteringTable(), this.state.sortKey, this.state.sortType).map(row =>(
                <TableRow key={row.key} className={this.coloring(row)}>
                  {this.state.editableElement === null || row.key !== this.state.editableElement.key ?
                    <>
                      <TableCell component="th" scope="row">
                        <Grid>
                          <Tooltip title="Törlés">
                            <IconButton aria-label="Delete" onClick={() => this.handleDelete(row.key)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Szerkesztés">
                            <IconButton aria-label="Edit" onClick={() => {this.handleEdit(row); console.log(this.state.editableElement);}}>
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </TableCell>

                      <TableCell > {row.name == null ? '-' : row.name }</TableCell>
                      <TableCell> {row.quantity == null ? '-' : row.quantity+' '+row.unit} </TableCell>
                      <TableCell>
                        {row.room == null ? '-' : row.room}
                        {row.place == null ? null : '->'+row.place}
                      </TableCell>
                      <TableCell> {row.owner == null ? '-' : row.owner } </TableCell>
                      <TableCell> {row.expiration_date == null ? '-' : this.convertExpirationDate(row.expiration_date) } </TableCell>
                      <TableCell>
                        {row.colors == null ? '-' : row.colors.map( C =>(
                          <div key={C} className={classes.colors} style={{backgroundColor:C}}/>
                        ))}
                      </TableCell>

                      <TableCell>
                        {row.brigades == null ? '-' : row.brigades/*: row.brigades.map( brigade =>(
                          brigade +'/'
                        ))*/}
                      </TableCell>
                      <TableCell>
                        {row.category == null ? '-' : row.category/*row.categories.map( category =>(
                          category +'/'
                        ))*/}
                      </TableCell>
                    </>
                  :
                  <>
                   {/******************SZERKESZTÉS NÉZETNÉL***************/}
                      <TableCell component="th" scope="row">
                        <Grid>
                          <Tooltip title="Mégse">
                            <IconButton aria-label="Back" onClick={() => this.editOff()}>
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Mentés">
                            <IconButton aria-label="Save" onClick={() => {this.saveEditable(this.state.editableElement,this.state.editableElement.key)}}>
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </TableCell>


                      <TableCell>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Név"
                          type="text"
                          value={this.state.editableElement.name}
                          onChange={(event) => this.setEditable('name', event.target.value)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.numberTextfield}
                          margin="dense"
                          id="quantity"
                          label="Mennyiség"
                          type="number"
                          value={this.state.editableElement.quantity}
                          onChange={(event) => this.setEditable('quantity', event.target.value)}
                        />
                        <FormControl >
                          <InputLabel id="unit">Mértékegység</InputLabel>
                          <Select
                            labelId="unit"
                            id="unit"
                            value={this.state.editableElement.unit}
                            onChange={(event) => this.setEditable('unit', event.target.value)}
                          >
                            {units.map(unit => (
                              <MenuItem key={unit} value={unit}> {unit} </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl /*className={classes.formControl}*/>
                          <InputLabel id="room">Szoba</InputLabel>
                          <Select
                            labelId="room"
                            id="room"
                            value={this.state.editableElement.room === null ? '-' : this.state.editableElement.room}
                            onChange={(event) => this.setEditable('room', event.target.value)}
                          >
                            {this.props.rooms.map(room => (
                              <MenuItem key={room.name} value={room.name}> {room.name} </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl /*className={classes.formControl}*/>
                          <InputLabel id="place">Hely</InputLabel>
                          <Select
                            labelId="place"
                            id="place"
                            value={this.state.editableElement.place === null ? '-' : this.state.editableElement.place}
                            onChange={(event) => this.setEditable('place', event.target.value)}
                          >
                            {this.props.rooms.map(room => (
                              room.places.map(place => (
                                <MenuItem key={place} value={place}> {place} </MenuItem>
                              ))
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl /*className={classes.formControl}*/>
                          <InputLabel id="owner">Tulaj</InputLabel>
                          <Select
                            labelId="owner"
                            id="owner"
                            value={this.state.editableElement.owner === null ? '-' : this.state.editableElement.owner}
                            onChange={(event) => {this.setEditable('owner', event.target.value)}}
                          >
                            {this.props.owners.map(owner => (
                              <MenuItem key={owner} value={owner}> {owner} </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {this.state.datePickerOpen === false ?
                            <IconButton onClick={() => this.setState({...this.state, datePickerOpen: true/*, data:{...this.state.data, expiration_date: new Date()}*/})}>
                              <EventAvailableIcon/>
                            </IconButton>
                          :
                          <>
                            <Grid item xs={1}>
                              <IconButton onClick={() => this.setState({...this.state, datePickerOpen: false/*, data:{...this.state.data, expiration_date: null}*/})}>
                                <EventBusyIcon/>
                              </IconButton>
                            </Grid>
                            <Grid item xs={5}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="yyyy.MM.dd."
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Lejárati dátum"
                                  value={this.state.editableElement.expiration_date}
                                  onChange={(event) => this.setData('expiration_date', event)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </>
                        }
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <DeleteDialog
          id='storage'
          deletableKey={this.state.deletableElementKey}
          open={this.state.deleteDialogOpen}
          onClose={() => this.setState({...this.state, deleteDialogOpen: false})}
        />

        <AddDialog
          open={this.state.addDialogOpen}
          onClose={() => this.setState({...this.state, addDialogOpen: false})}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    storage: state.storage,
    rooms: state.rooms,
    owners: state.owners,
    buttonArray: state.pushedStorageButtons,
    rights: state.userData.rights,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addData: (data) => dispatch(addFirebase(data, 'storage')),
    editData: (data, key) => dispatch(addFirebase(data, 'storage/' + key, 'PUT')),
    getStorage: () => dispatch(getFirebase('storage', 'storage', 'array')),
    setStorage: (data) => dispatch(setFirebase(data, 'storage')),
    //setSearch: (data) => dispatch(setStorageFormData(data, 'search')),
  }
}

export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(StorageTable));


//Hozzáadás dátuma
//Led izzó a HÖK-é
//hangcucc, égősr hök