import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import JB from './JB.js';
import Attic from './Attic.js';
import Basement from './Basement.js';


import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


/*const useStyles = makeStyles(theme => ({
  
}));*/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

class Filter extends React.Component {

  state={
    value: 0,
  }
  
  handleChange = (event, newValue) => {
    this.setState({...this.state,
      value: newValue,
    })
  };

  handleChangeIndex = index => {
    this.setState({...this.state,
      value: index,
    })
  };

  //const classes = useStyles();
  render(){
    return(
      <Grid container justify='center'>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab label="Jegyzetbolt" /*{...a11yProps(0)}*/ />
            <Tab label="Padlás" /*{...a11yProps(1)}*/ />
            <Tab label="Pince" /*{...a11yProps(2)}*/ />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={false ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
        <TabPanel value={0} index={0} /*dir={theme.direction}*/>
          <JB/>
        </TabPanel>
        <TabPanel value={1} index={1} /*dir={theme.direction}*/>
          <Attic/>
        </TabPanel>
        <TabPanel value={2} index={2} /*dir={theme.direction}*/>
          <Basement/>
        </TabPanel>
      </SwipeableViews>
        
      </Grid>
    );
  }
}

export default Filter;