import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  pressStorageButton
} from '../../Store/functions.js';

import Tooltip from '@material-ui/core/Tooltip';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';


const useStyles = theme => ({
  on:{
    backgroundColor: '#1f1',
  },
  off:{
    //backgroundColor: '#ff9',
  },
  smallButtton:{
    width: 50,
    height: 30,
  },
  middleButton:{
    width: 50,
    height: 40,
  },
  bigButton:{
    width: 130,
    height: 80,
  },
});


class JB extends React.Component {

  setColor = (id) => {
    if(this.props.buttonArray.indexOf(id) > -1)
      return this.props.classes.on;
    return this.props.classes.off;
  }

  JBWestSide = () => {
    let table = [];
    for (let i = 8; i > 0; i--) {
      let children = []
      for (let j = 65; j < 65+5; j++) { //ASCII 65 == 'A'
        children.push(
          <td key={String.fromCharCode(j)+'/'+i}>
            <Button
              key={String.fromCharCode(j)+'/'+i}
              variant='outlined'
              className={[this.setColor(String.fromCharCode(j)+'/'+i), this.props.classes.smallButtton]}
              onClick = {() => this.props.pressButton(String.fromCharCode(j)+'/'+i)}
              >
              {String.fromCharCode(j)+'/'+i}
            </Button>
          </td>
        )
      }
      if(i === 4)
        children.push(<Divider/>)
      table.push(<tr key={i}>{children}</tr>)
    }
    return table;
  }

  JBEastSide = () => {
    let table = [];
    let columnNames= ['AMP', 'Ü/1', 'Ü/2'];
    let titles = ['Ajtó melletti polc', 'Üveges szekrény/1', 'Üveges szekrény/2'];
    
    for (let i = 6; i > 0; i--) {
      let children = []
      for (let j = 0; j < 3; j++) {
        children.push(
          <td key={columnNames[j]+'/'+i}>
            <Tooltip title={titles[j]+'/'+i}>
              <Button
                variant='outlined'
                className={[this.setColor(columnNames[j]+'/'+i), this.props.classes.middleButton]}
                onClick = {() => this.props.pressButton(columnNames[j]+'/'+i)}
              >
                {columnNames[j]+'/'+i}
              </Button>
            </Tooltip>
          </td>
        )};
      if(i ===3){
        children.push(
        <td key='Bal fiók'>
          <Tooltip title="Bal fiók">
            <Button
              variant='outlined'
              className={[this.setColor('Bal fiók'), this.props.classes.middleButton]}
              onClick = {() => this.props.pressButton('Bal fiók')}
            >
              BF
            </Button>
          </Tooltip>
        </td>)
        children.push(<td key='Jobb fiók'>
          <Tooltip title="Jobb fiók">
            <Button
              variant='outlined'
              className={[this.setColor('Jobb fiók'), this.props.classes.middleButton]}
              onClick = {() => this.props.pressButton('Jobb fiók')}
            >
              JF
            </Button>
          </Tooltip>
        </td>)
      }else if(i === 2){
        children.push(<td key='Asztal alatt' colSpan='2' rowSpan="2">
          <Button
            variant='outlined'
            className={[this.setColor('Asztal alatt'), this.props.classes.bigButton]}
            onClick = {() => this.props.pressButton('Asztal alatt')}
          >
            Asztal alatt
          </Button>
        </td>)
      }
      table.push(<tr key={i}>{children}</tr>)
    }
    return table;
  }

  render(){
    return(
      <div style={{overflow: 'hidden'}}>
        <h3>Jegyzetbolt</h3>
        <div key='JBWestSide' style={{float: 'left'}}>
          <table>
            <tbody>
              {this.JBWestSide()}
              
            </tbody>
          </table>
        </div>
        <div key='JBEastSide' style={{overflow: 'hidden'}}>
          <table>
            <tbody>
              {this.JBEastSide()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    buttonArray: state.pushedStorageButtons,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    pressButton: (id) => dispatch(pressStorageButton(id)),
  }
}

export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(JB));
