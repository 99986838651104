import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  getFirebase,
  addFirebase,
  authentication,
} from '../Store/functions.js';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = theme => ({
  root:{
    width: '200px',
  },
  error: {
    color: '#f00',
  },
});


class Kgy extends React.Component {

  componentDidMount = () => {
    this.props.getJelenVolt(this.props.uid);
    this.props.getPassword();
    this.props.authentication();
  }

  state={
    password: '',
    wrongPassword: false,
    jelenVolt: false,
  }

  checkPassword = () => {
    if(this.props.password !== '' && this.state.password === this.props.password){
      this.setState({...this.state, wrongPassword: false, jelenVolt: true});
      this.props.addData({jelenlet: true}, this.props.uid);
    }else{
      this.setState({...this.state, wrongPassword: true})
    }
    this.props.getJelenVolt(this.props.uid);
    
  }

  render(){
    const {classes} = this.props;
    return(
      <Paper>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{minHeight: 300}}
        >
        {this.state.jelenVolt === false ?
          <>
            <Grid item xs={12}>
              <Typography> Közgyűlés </Typography>
            </Grid>
            {!this.state.wrongPassword ? null :
              <Grid item xs={12}>
                <Typography className={classes.error}> Rossz jelszó :( </Typography>
              </Grid> 
            }
            <Grid item xs={12}>
              <TextField
                id="kgy-password-input"
                label="Jelszó"
                type="password"
                autoComplete="current-password"
                //variant="outlined"
                value={this.state.password}
                onChange={(event) => this.setState({...this.state, password:event.target.value})}
              />
              </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={() => this.checkPassword()}>Ok</Button>
            </Grid>
          </>
          :
          <Typography>Jelenlétét rögzítettük</Typography>
        }
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    password: state.kgyPassword,
    uid: state.userData.uid,
    jelenVolt: state.kgyJelenlet,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addData: (data, key) => dispatch(addFirebase(data, 'jelenlet/' + key, 'PUT')),
    getPassword: () => dispatch(getFirebase('events/-M-ZWoD_P7eAqvOHJAPh/password', 'kgyPassword')),
    getJelenVolt: (uid) => dispatch(getFirebase('jelenlet/'+uid, 'kgyJelenlet')),
    authentication: () => dispatch(authentication()),
  }
}

export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(Kgy));