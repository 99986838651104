import React from 'react';
//import axios from 'axios';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

//import Button from '@material-ui/core/Button';

//import { makeStyles } from '@material-ui/core/styles';


/*const useStyles = makeStyles(theme => ({
  
}));*/


class Home extends React.Component {
  componentDidMount(){
    //this.props.authentication();
    /*axios.get('https://ak.itk.ppke.hu/whoami/index.php')
    .then(res => {
      console.log(res)
      const persons = res.data;
      this.setState({persons})
    })*/
  }
  //const classes = useStyles();
  render(){
    return(
        <Typography>
          Üdv {this.props.userData.name}!<br/>
          {/*Üdvözlünk a megújult Animátor honlapon!
          Ha bármi észrevételed van az oldallal kapcsolatban,
          kérlek írj egy e-mailt az animator.kozosseg@gmail.com címre,
          vagy keresd meg bármelyik vezetőségi tagot.
          Bármilyen apró észrevételt szívesen veszünk :)*/}
        </Typography>
    );
  }
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    sum: state.sum,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    //authentication: () => dispatch(authentication()),
    //getSum: () => dispatch(getFirebase('jelenlet', 'sum')),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);