import React from 'react';
import Table from './Table.js';
import Filter from './Filtering/Filter.js';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


class Storage extends React.Component {
  state={
    coloringType: 'none',
  }

  render(){
    return(
      <Grid container justify='center'>
        <Grid item xs={10}>
          <Filter/>
        </Grid>
        <Grid item xs={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Színezés</FormLabel>
            <RadioGroup name="coloring" value={this.state.coloringType} onChange={(event)=> this.setState({...this.state, coloringType: event.target.value})}>
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="Nincs"
              />
              <FormControlLabel
                value="freshability"
                control={<Radio />}
                label="Romlandóság"
              />
              <FormControlLabel
                value="disabled"
                disabled
                control={<Radio />}
                label="Foglalás/elvitel"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <Table
            coloringType={this.state.coloringType}
          />
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, null)(Storage);
