import React from 'react';
import { connect } from 'react-redux';
import {
  delFirebase
} from '../Store/functions.js';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';


class DeleteDialog extends React.Component {
  state={
    openSnackBar: false,
    snackBarData: 'Nincs jogosultságod törölni az elemet.',
  }

  haveRight = (rightName) => {
    /*for (var id in this.props.rights) {
      if(this.props.rights[id] === rightName)
        return true;
    }
    return false;*/
    return true;
  }
  handleCloseSnackBar = (event, reason) => {
    this.setState({...this.state, openSnackBar:false});
  }

  handleDelete = () => {
    if(this.haveRight('raktáros')){
      this.props.del(this.props.id, this.props.deletableKey);
    }else{
      this.setState({...this.state,
        openSnackBar: true,
      });
    }
    this.props.onClose();
  }

  render(){
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => this.props.onClose()}
        >
          <DialogTitle id="delete-dialog-title">{"Biztosan kitörlöd az adott elemet?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Az adott elem kitörlését már nem vonhatod vissza.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color="primary">
              Mégse
            </Button>
            <Button onClick={() => this.handleDelete()} color="primary">
              Törlés
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          onClose={(event, reason) => this.handleCloseSnackBar(event, reason)}
          message={this.state.snackBarData}
        >
        </Snackbar>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    rights: state.userData.rights,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    del:(id = null, key = null) => dispatch(delFirebase(id,key,'storage','array')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);