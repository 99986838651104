import React from 'react';

import Button from '@material-ui/core/Button';



class Basement extends React.Component {
  
  render(){
    return(
      <Button variant='outlined' style={{width:200, height:100}}>
        Pince
      </Button>
    );
  }
}

export default Basement;