import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { authentication} from './Store/functions.js';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import Divider from '@material-ui/core/Divider';
//import HomeIcon from '@material-ui/icons/Home';

const useStyles = theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

class Menu extends React.Component {
  componentDidMount = () => {
    this.props.authentication();
  }
  render(){
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        {/*<List component="nav">
          <ListItemLink href='/'>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemLink>
        </List>
        <Divider/>*/}
        <List component="nav">
          <ListItemLink href="/raktar">
            <ListItemText primary="Raktár" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/kozgyules">
            <ListItemText primary="Közgyűlés" />
          </ListItemLink>
        </List>
        {/*this.props.uid === 'fabno' || this.props.uid === 'kanno69'*/false ? 
          <List component="nav">
          <ListItemLink href="/esemenyek">
            <ListItemText primary="Események" />
          </ListItemLink>
        </List> : null}
        {/*
        <List component="nav">
          <ListItemLink href="/forum">
            <ListItemText primary="Fórum" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/Naptar">
            <ListItemText primary="Naptár" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/Wiki">
            <ListItemText primary="Wiki" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/RolunkIrtak">
            <ListItemText primary="Rólunk írták" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/SzabalyzatokJegyzokonyvek">
            <ListItemText primary="Szabályzatok, jegyzőkönyvek" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/golyaknak">
            <ListItemText primary="Gólyáknak" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/kapcsolat">
            <ListItemText primary="Kapcsolat" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/taglistaIdovonal">
            <ListItemText primary="Taglista, idővonal" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/KepezdMagad">
            <ListItemText primary="Képezd Magad" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/statuszujitas">
            <ListItemText primary="Státuszújítás" />
          </ListItemLink>
        </List>
        <List component="nav">
          <ListItemLink href="/kepek">
            <ListItemText primary="Képek" />
          </ListItemLink>
        </List>*/}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    uid: state.userData.uid,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authentication: () => dispatch(authentication()),
  }
}

export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(Menu));