import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';

import ColorLensIcon from '@material-ui/icons/ColorLens';
import AddIcon from '@material-ui/icons/Add';
import StopRoundedIcon from '@material-ui/icons/StopRounded';

/*const useStyles = makeStyles(theme => ({
  
}));*/

const simpleColors = [
  '#000000', //Fekete
  '#ffffff', //Fehér
  '#888888', //Szürke
  '#8B0000', //Dark-red
  '#ff0000', //Piros
  '#ff8c00', //Narancs
  '#ffff00', //Citrom
  '#00ff00', //Világoszöld
  '#006400', //Sötétzöld
  '#00bfff', //Világoskék
  '#00008B', //Sötétkék
  '#9400D3', //Lila
  '#FF69B4', //Rózsaszín
  '#8B4513' //Barna
]

class OwnColorPicker extends React.Component{
  state={
    open:false,
    anchorEl: null,
    actualColor: '#000000',

  }

  openPicker = (event) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      open: true,
    })
  }

  closePicker = () => {
    this.setState({
      ...this.state,
      open: false,
    })
  }

  setColor = (C) => {
    this.setState({
      ...this.state,
      actualColor: C,
    })
  }

  addColor = (C) => {
    if(this.props.value.length >= this.props.maxColor || 
      this.props.value.find(element => element === C) !== undefined)
      return
    
    this.props.onChange(this.props.value.concat(C));
  }

  deleteColor = (C) => {
    let newColors = this.props.value;
    for(var i = 0; i < newColors.length; i++){ 
      if(newColors[i] === C){
        newColors.splice(i, 1);
        break;
      }
    }

    this.props.onChange(newColors);
  }

  createColorTable = (n,m) => {
    let table = [];

    for (let i = 0; i < n; i++) {
      let children = []
      for (let j = 0; j < m; j++) {
        children.push(<td>{i*m+j >= simpleColors.length ? null :
          <IconButton
            style={{color: simpleColors[i*m+j], backgroundColor:'#eee'}}
            onClick={() => {this.setColor(simpleColors[i*m+j]); this.addColor(simpleColors[i*m+j])}}
          >
            <ColorLensIcon/>
          </IconButton>
        }</td>)
      }
      table.push(<tr>{children}</tr>)
    }
    return table;
  }

  /*onChange = (C) => {
    this.props.onChange(C);
  }*/

  //const classes = useStyles();
  render(){
    return(
      <div>
        <Popover
          id={'simple-popover'}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.closePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        {this.createColorTable(3,5)}
        </Popover>
        <IconButton  onClick={this.openPicker}//this.addColor(this.state.actualColor)}
          >
          <ColorLensIcon/>
        </IconButton>
        <TextField
          id="color-picker"
          label="Szín"
          placeholder="Pl.: #A1B2C3"
          multiline
          value={this.state.actualColor}
          onChange={(event) => this.setColor(event.target.value)}
        />
        {this.props.maxColor <= this.props.value.length ? null :
          <>
            <IconButton  onClick={() => this.addColor(this.state.actualColor)}//this.addColor(this.state.actualColor)}
            >
              <AddIcon/>
            </IconButton>
          </>
        }
        {this.props.value.map(C =>(
          <IconButton variant='outlined' key={C} onClick={() => this.deleteColor(C)} component="span">
            <StopRoundedIcon style={{color:C}}/>
          </IconButton>
        ))}
      </div>
    );
  }
}

export default OwnColorPicker;
