import React from 'react';
import { Provider } from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import reducer from './Store/reducer.js';
import thunk from 'redux-thunk';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Header from './Header.js';
import Menu from './Menu.js';

import Home from './Home/Home.js';
import Storage from './Storage/Storage.js';
import Kgy from './Kgy/kgy.js';
import Events from './Events/Events.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    //padding: 50
    width: '100%',
    height: '100%',
  },
  container:{
    height: '100%',
  },
  page: {
    
  },
  menu:{
    height: '100vh',
    minWidth: 120,
  },
}));

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default function App() {
  const classes = useStyles();

  return (
    <Provider className={classes.root} store={store}>
      <Header/>
      <Grid container className={classes.container} justify="flex-start">
        <Grid className={classes.menu} item xs={1}>
          <Menu id='menu'/>
        </Grid>
        <Grid item xs={11} className={classes.page}>
          <Router>
            <Route exact path='/' component={Home}/>
            <Route path='/raktar' component={Storage}/>
            <Route path='/kozgyules' component={Kgy}/>
            <Route path='/esemenyek' component={Events}/>
            {/*<Route path='/Configs' component={Configs}/>
            <Route path='/Coupons' component={Coupons}/>
            <Route path='/Games' component={Games}/>
            <Route path='/MainSlider' component={MainSlider}/>
            <Route path='/RecommendRestaurant' component={RecommendRestaurant}/>
            <Route path='/Restaurants' component={Restaurants}/>
            <Route path='/Stories' component={Stories}/>
            <Route path='/Users' component={Users}/>
    <Route path='/Winners' component={Winners}/>*/}
          </Router>
        </Grid>
      </Grid>
      
    </Provider>
  );
}
