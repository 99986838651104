import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
//import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';

import {
  addFirebase
} from '../Store/functions.js';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import ColorPicker from './ColorPicker.js';

import Snackbar from '@material-ui/core/Snackbar';
//import MuiAlert from '@material-ui/lab/Alert';


/*function Alert(props) {
  return <MuiAlert elevation={1} variant="filled" {...props} />;
}*/

/*const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  name:{
    width: '100%',
  },
  colorPicker:{
    width: '50%', //nem megy :(
  }
}));
*/

class AddDialog extends React.Component {
  state={
    actualColor:'#000000',
    datePickerOpen: false,
    openSnackBar: false,
    data: {
      name: '',
      quantity: 1,
      unit: 'db',
      expiration_date: null,
      room: '-',
      place: '-',
      category: '-',
      owner: '-',
      colors: [],
      brigades: '-',
    },
    snackBarData: 'Valami hiba merült fel a rendszerben... :(',
  }

  setActualColor = (color) => {
    this.setState({...this.state,
      actualColor: color,
    })
  }

  setData = (id, data) => {
    //const seged = new Date(action.data);
    if(id === 'expiration_date'){
      data.setHours(20);
    }
    this.setState({...this.state,
      data:{...this.state.data,
        [id]: data,
      }});
  }

  handleClose = () => {
    this.props.onClose();
  };

  handleCloseSnackBar = (event, reason) => {
    this.setState({...this.state, openSnackBar:false});
  }

  handleAdd = () => {
    if(!this.haveRight('raktáros')){
      var seged = this.state.data;
      for(var key in seged){
        if(seged[key] === '-' || seged[key] === '' || seged[key] === []){
          seged[key] = null;
        }
      }
      seged = {...seged,
        dateAdd: new Date(),
        lastModified: new Date(),
        //whoModif
      }
      this.props.addData(seged)
      this.setState({...this.state,
        openSnackBar: true,
        snackBarData: this.state.data.quantity + " " + this.state.data.unit + " " + this.state.data.name + " sikeresen hozzáadva!",
      })
    }else{
      this.setState({...this.state,
        openSnackBar: true,
        snackBarData: 'Nincs jogosultságod a táblázat módosításához.',
      })
    }
  }

  haveRight = (rightName) => {
    //console.log(this.props.rights)
    for (var id in this.props.rights) {
      if(this.props.rights[id] === rightName)
        return true;
    }
    return false;
  }


  //const classes = useStyles();
  render(){
    return (
      <div>
        <Dialog open={this.props.open} onClose={() => this.handleClose()} aria-labelledby="dialog-title">
          <DialogTitle id="dialg-title">Új elem</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Az új elem hozzáadásához kérlek töltsd ki a formot!
            </DialogContentText>
              <Grid container justify='flex-start'>

              {/* -------------NÉV------------*/}
                <Grid item xs={6}>
                  <TextField
                    /*className = {classes.name}*/
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Név"
                    type="text"
                    value={this.state.data.name}
                    onChange={(event) => this.setData('name', event.target.value)}
                    fullWidth
                  />
                </Grid>

              {/* -------------MENNYISÉG------------*/}
                <Grid item xs={4}>
                  <TextField
                    /*className = {classes.name}*/
                    margin="dense"
                    id="quantity"
                    label="Mennyiség"
                    type="number"
                    InputProps={{ inputProps: { min: 0}}}
                    value={this.state.data.quantity}
                    onChange={(event) => this.setData('quantity', event.target.value)}//console.log(event.target.value)}
                    fullWidth
                  />
                </Grid>

                {/* -------------MÉRTÉKEGYSÉG------------*/}
                  <Grid item xs={2}>
                    <FormControl /*className={classes.formControl}*/>
                      <InputLabel id="unit">Mértékegység</InputLabel>
                      <Select
                        labelId="unit"
                        id="unit"
                        value={this.state.data.unit}
                        onChange={(event) => {this.setData('unit', event.target.value)}}
                      >
                        {this.props.units.map(unit => (
                          <MenuItem key={unit} value={unit}> {unit} </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

              {/* -------------LEJÁRATI_DÁTUM------------*/}
                
                  {this.state.datePickerOpen === false ?
                    <Grid item xs={6}>
                      <IconButton onClick={() => this.setState({...this.state, datePickerOpen: true, data:{...this.state.data, expiration_date: new Date()}})}>
                        <EventAvailableIcon/>
                      </IconButton>
                    </Grid>
                    :
                    <>
                      <Grid item xs={1}>
                        <IconButton onClick={() => this.setState({...this.state, datePickerOpen: false, data:{...this.state.data, expiration_date: null}})}>
                          <EventBusyIcon/>
                        </IconButton>
                      </Grid>
                      <Grid item xs={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy.MM.dd."
                            margin="normal"
                            id="date-picker-inline"
                            label="Lejárati dátum"
                            value={this.state.data.expiration_date}
                            onChange={(event) => this.setData('expiration_date', event)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </>
                  }

              {/* -------------SZOBA------------*/}
                <Grid item xs={3}>
                  <FormControl /*className={classes.formControl}*/>
                    <InputLabel id="room">Szoba</InputLabel>
                    <Select
                      labelId="room"
                      id="room"
                      value={this.state.data.room === null ? '-' : this.state.data.room}
                      onChange={(event) => {this.setData('room', event.target.value)}}
                    >
                      {this.props.rooms.map(room => (
                        <MenuItem key={room.name} value={room.name}> {room.name} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

              {/* -------------HELY------------*/}
                <Grid item xs={3}>
                  <FormControl /*className={classes.formControl}*/>
                    <InputLabel id="place">Hely</InputLabel>
                    <Select
                      labelId="place"
                      id="place"
                      value={this.state.data.place === null ? '-' : this.state.data.place}
                      onChange={(event) => {this.setData('place', event.target.value)}}
                    >
                      {this.props.rooms.map(room => (
                        room.places.map(place => (
                          <MenuItem key={place} value={place}> {place} </MenuItem>
                        ))
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

              {/* -------------KATEGÓRIA------------*/}
                <Grid item xs={6}>
                  <FormControl /*className={classes.formControl}*/>
                    <InputLabel id="category">Kategória</InputLabel>
                    <Select
                      labelId="category"
                      id="category"
                      value={this.state.data.category === null ? '-' : this.state.data.category}
                      onChange={(event) => {this.setData('category', event.target.value)}}
                      >
                      {this.props.categories.map(category => (
                        <MenuItem key={category} value={category}> {category} </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

              {/* -------------TULAJ------------*/}
                <Grid item xs={6}>
                  <FormControl /*className={classes.formControl}*/>
                    <InputLabel id="owner">Tulaj</InputLabel>
                    <Select
                      labelId="owner"
                      id="owner"
                      value={this.state.data.owner === null ? '-' : this.state.data.owner}
                      onChange={(event) => {this.setData('owner', event.target.value)}}
                    >
                      {this.props.owners.map(owner => (
                        <MenuItem key={owner} value={owner}> {owner} </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

              {/* -------------BRIGÁD------------*/}
                <Grid item xs={12}>
                  <FormControl /*className={classes.formControl}*/>
                    <InputLabel id="brigade">Brigád</InputLabel>
                    <Select
                      labelId="brigade"
                      id="brigade"
                      value={this.state.data.brigades === null ? '-' : this.state.data.brigades}
                      onChange={(event) => {this.setData('brigades', event.target.value)}}
                    >
                      {this.props.brigades.map(brigade => (
                        <MenuItem key={brigade} value={brigade}> {brigade} </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

              {/* -------------SZÍN------------*/}
                <Grid item xs={12}>
                  <ColorPicker
                    maxColor={4}
                    actualColor={this.state.actualColor}
                    value={this.state.data.colors}
                    onChange={(C)=> this.setData('colors', C)}
                  />
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Vissza
            </Button>
            <Button onClick={() => this.handleAdd()} color="primary">
              Hozzáad
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.openSnackBar}
          autoHideDuration={6000}
          onClose={(event, reason) => this.handleCloseSnackBar(event, reason)}
          message={this.state.snackBarData}
        >
        </Snackbar>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    rights: state.userData.rights,

    units: state.units,
    rooms: state.rooms,
    categories: state.categories,
    owners: state.owners,
    brigades: state.brigades,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addData: (data) => dispatch(addFirebase(data, 'storage')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDialog);