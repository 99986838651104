import * as ActionType from './actions';

const initialState = {
  storage: [],
  userData:{
    shibboleth: 'valki5',
    name: 'Vala Kira',
    rights:{
      //0: 'raktáros',
    },
  },
  kgyPassword: '',
  kgyJelenlet: null,

  ///Kategóriák
  brigades:[
    '-',
    'Büfé',
    'Dekor',
    'Konyha',
    'Média és Entertain',
    'Paku',
    'Prog',
  ],
  categories: [
    '-',
    'Egészségügyis doboz',
    'Egyéb',
    'Elektronikus eszköz',
    'Élelmiszer alapanyag',
    'Élelmiszer késztermék',
    'Festék',
    'Használati tárgy',
    'Homemade kellék',
    'Játék',
    'Kötöző anyag',
    'Papír',
    'Ragasztó',
    'Szövet',
    'Világító eszköz',
  ],
  rooms: [{
    name: '-',
    places: ['-'],
  },{
    name: 'Jegyzetbolt',
    places: [
      '-',
      'A/1',
      'A/2',
      'A/3',
      'A/4',
      'A/5',
      'A/6',
      'A/7',
      'A/8',
      'B/1',
      'B/2',
      'B/3',
      'B/4',
      'B/5',
      'B/6',
      'B/7',
      'B/8',
      'C/1',
      'C/2',
      'C/3',
      'C/4',
      'C/5',
      'C/6',
      'C/7',
      'C/8',
      'D/1',
      'D/2',
      'D/3',
      'D/4',
      'D/5',
      'D/6',
      'D/7',
      'D/8',
      'E/1',
      'E/2',
      'E/3',
      'E/4',
      'E/5',
      'E/6',
      'E/7',
      'E/8',
      'Ajtó melleti polc/1',
      'Ajtó melleti polc/2',
      'Ajtó melleti polc/3',
      'Ajtó melleti polc/4',
      'Ajtó melleti polc/5',
      'Ajtó melleti polc/6',
      'Üvegszekrény/A/1',
      'Üvegszekrény/A/2',
      'Üvegszekrény/A/3',
      'Üvegszekrény/A/4',
      'Üvegszekrény/A/5',
      'Üvegszekrény/A/6',
      'Üvegszekrény/B/1',
      'Üvegszekrény/B/2',
      'Üvegszekrény/B/3',
      'Üvegszekrény/B/4',
      'Üvegszekrény/B/5',
      'Üvegszekrény/B/6',
      'Bal fiók',
      'Jobb fiók',
      'Asztal alatt'
    ]
  },{
    name: 'Padlás',
    places: ['-'],
  },{
    name: 'Pince',
    places: ['-'],
  },{
    name: 'Magánszemélynél',
    places: ['-'],
  }],
  owners: ['-','AK', 'HÖK', 'Magánszemély'],
  units: ['db', 'l', 'kg', 'doboz'],
  pushedStorageButtons: [],
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case ActionType.SET_FIREBASE:
      return {...state, [action.variableName]: action.data}
    case ActionType.PRESS_STORAGE_BUTTON:
      let segedGombokArray = state.pushedStorageButtons.slice();
      const index = segedGombokArray.indexOf(action.id);
      if (index > -1) {
        segedGombokArray.splice(index, 1);
      }else{
        segedGombokArray.splice(segedGombokArray.length,0,action.id);
      }
      return {...state, pushedStorageButtons: segedGombokArray};
    default:
      return state;
  }

}
export default reducer;