import React from 'react';
import { connect } from 'react-redux';
import {
  addFirebase,
} from '../Store/functions.js';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
//import FilledInput from '@material-ui/core/FilledInput';
//import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
//import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';



import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = theme => ({
  FormControl:{
    minWidth: '200px',
  }
  
});


class Events extends React.Component {
  state={
    eventTypes:[
      'közgyűlés',
      'egyetemi rendezvény',
      'nagyrendezvény',
      'egyéb'
    ],
    event:{
      name: '',
      type: 'egyetemi rendezvény',
      date: new Date(),
      password: '',
    },
    showPassword: false,
  }
  handleChangeName = (e) =>{
    this.setState({...this.state,
      event: {...this.state.event,
        name: e.target.value,
      }
    })
  }

  handleChangeType = (e) => {
    this.setState({...this.state,
      event: {...this.state.event,
        type: e.target.value,
      }
    })
  }

  handleChangeDate = (e) => {
    this.setState({...this.state,
      event: {...this.state.event,
        date: e,
      }
    })
  }

  handleChangePassword = (e) => {
    //console.log(e)
    this.setState({...this.state,
      event: {...this.state.event,
        password: e.target.value,
      }
    })
  }

  handleClickShowPassword = () => {
    this.setState({...this.state,
      showPassword: !this.state.showPassword,
    })
  }

  handleOnOk = () => {
    //console.log(this.state.event)
    let alma = 'korte'
    if(this.state.event.type !== 'közgyűlés')
      this.setState({...this.state,event:{...this.event, password: null}})
    this.setState({...this.state, [alma]: 'alma'});
    //this.props.addData(this.state.event)
    console.log(this.state)
  }

  seged = () => {
    //this.props.addData(segedAdat);
  }
  
  render(){
    const {classes} = this.props;
    return(
      <Paper>
        <TextField
          autoFocus
          margin="dense"
          id="even-name"
          label="Esemény neve"
          type='text'
          value={this.state.event.name}
          onChange={(e) => this.handleChangeName(e)}
        />
        <br/>
        <FormControl className={classes.FormControl}>
          <InputLabel id="event-type-label">Esemény típusa</InputLabel>
          <Select
            id="event-type"
            value={this.state.event.type}
            onChange={(e) => this.handleChangeType(e)}
          >
            {this.state.eventTypes.map(e => (
              <MenuItem key={e} value={e}> {e}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.state.event.type === 'közgyűlés' ?
          <>
            <br/>
            <FormControl /*className={clsx(classes.margin, classes.textField)}*/>
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.event.password}
                onChange={(event) => this.handleChangePassword(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => this.handleClickShowPassword()}
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </>
          : null
        }
        <br/>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy.MM.dd."
            margin="normal"
            id="date-picker-inline"
            label="Esemény kezdete"
            value={this.state.date}
            onChange={(e) => this.handleChangeDate(e)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardTimePicker
            variant="inline"
            margin="normal"
            id="time-picker"
            label="Idő"
            value={this.state.date}
            onChange={(e) => this.handleChangeDate(e)}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
          </MuiPickersUtilsProvider>
          <br/>
          <Button
            variant='outlined'
            onClick={() => this.seged()/*this.handleOnOk()*/}
          >
            OK
          </Button>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    storage: state.storage,
    search: state.storageSearchField,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addData: (data) => dispatch(addFirebase(data, 'events', 'PATCH')),
    //getStorage: () => dispatch(getFirebase('events')),
  }
}

export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(Events));
